import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
  // strict: false,
Vue.config.strict = false

/* 导入axios到main.js中 */
import axios from 'axios'

/* 将axios挂在到Vue实例上 今后请求数据可以直接调用 this.$http.get()进行请求操作 */
Vue.prototype.$http = axios

// /* 设置axios默认访问接口地址  	onesapi.semoun.com */
axios.defaults.baseURL = 'https://ccloth.cn'
// axios.defaults.baseURL = 'http://192.168.0.104:5501'

axios.interceptors.request.use(config => {
  // 配置 Token 认证 Ones_admin_token
  config.headers.usercoode = localStorage.getItem('Ones_user_token')
  config.headers.authorization = localStorage.getItem('Ones_admin_token')
  console.log(config)
  // 这是固定写法
  return config
})

Vue.use(ElementUI);
new Vue({
  strict: false,
  router,
  render: h => h(App),
}).$mount('#app')