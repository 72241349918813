import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Index/Login",
  },
  {
    path: "/Admin",
    redirect: "/Admin/Home",
  },
  {
    path: "/Index",
    name: "Index",
    component: () => import("@/views/user/Index_all.vue"),
    children: [
      { path: "Home", component: () => import("@/views/user/home/Index_home.vue")},
      { path: "Log", component: () => import("@/views/user/com/log.vue")},
      { path: "Log_Data/:id", component: () => import("@/views/user/com/log_data.vue"),props:true},
      { path: "Login", component: () => import("@/views/user/User_Login.vue")},
    ],
  },
  {
    path: "/Admin",
    name: "Index",
    component: () => import("@/views/admin/index.vue"),
    children: [
      { path: "Home", component: () => import("@/views/admin/home/index.vue")},
      { path: "Inve/list", component: () => import("@/views/admin/inventory/list.vue")},
      { path: "Inve/data", component: () => import("@/views/admin/inventory/data.vue"),props:true},
      { path: "Inve/out", component: () => import("@/views/admin/outbound/list.vue"),props:true},
      { path: "Flaw/list", component: () => import("@/views/admin/flaw/list.vue")},
      { path: "Log/list", component: () => import("@/views/admin/log/list.vue")},
      { path: "user/list", component: () => import("@/views/admin/user/list.vue")},
      { path: "xy/list", component: () => import("@/views/admin/xy/list.vue")},
      { path: "lv/list", component: () => import("@/views/admin/lv/list.vue")},
    ],
  },
  {
    path: "/Admin/Login",
    name: "Login",
    component: () => import("@/views/admin/login.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
